import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VDialog,{attrs:{"persistent":"","max-width":"25%"},model:{value:(_vm.isAddendumFormActive),callback:function ($$v) {_vm.isAddendumFormActive=$$v},expression:"isAddendumFormActive"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"text-h5"},[_vm._v("Addendum Details")])]),_c(VCardText,[_c(VForm,{ref:"patientFormAddendum"},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VTextarea,{attrs:{"label":"Addendum","outlined":"","dense":"","rules":[_vm.required]},model:{value:(_vm.patientFormAddendumDTO.value),callback:function ($$v) {_vm.$set(_vm.patientFormAddendumDTO, "value", $$v)},expression:"patientFormAddendumDTO.value"}})],1)],1),_c(VRow,[_c(VCol,{staticClass:"text-right"},[_c(VBtn,{attrs:{"color":"error darken-1","type":"reset","text":""},on:{"click":function($event){return _vm.closeForm()}}},[_vm._v(" Close ")]),_c(VBtn,{attrs:{"color":"primary darken-1","text":""},on:{"click":function($event){return _vm.submitForm()}}},[_vm._v(" Save ")])],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }