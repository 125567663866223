<template>
  <div>
    <v-dialog
      v-model="isAddendumFormActive"
      persistent
      max-width="25%"
    >
      <v-card>
        <v-card-title>
          <span class="text-h5">Addendum Details</span>
        </v-card-title>
        <v-card-text>
          <v-form
            ref="patientFormAddendum"
          >
            <v-row>
              <v-col
                cols="12"
              >
                <v-textarea
                  v-model="patientFormAddendumDTO.value"
                  label="Addendum"
                  outlined
                  dense
                  :rules="[required]"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col class="text-right">
                <v-btn
                  color="error darken-1"
                  type="reset"
                  text
                  @click="closeForm()"
                >
                  Close
                </v-btn>
                <v-btn
                  color="primary darken-1"
                  text
                  @click="submitForm()"
                >
                  Save
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import store from '@/store'
import { required } from '@core/utils/validation'
import { ref, onUnmounted } from '@vue/composition-api'
import formService from '@/services/FormService'
import useFormService from '@/store/useFormService'

export default {
  model: {
    prop: 'isAddendumFormActive',
    event: 'update:is-addendum-form-active',
  },
  props: {
    isAddendumFormActive: {
      type: Boolean,
      required: true,
    },
    patientFormAddendumDTO: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    console.log(props.patientFormAddendumDTO)
    const FORM_APP = 'app-form'

    // Register module
    if (!store.hasModule(FORM_APP)) {
      store.registerModule(FORM_APP, formService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FORM_APP)) {
        store.unregisterModule(FORM_APP)
      }
    })

    const { postPatientFormAddendum } = useFormService()

    const patientFormAddendum = ref(null)

    const refreshParent = () => {
      emit('refetch-data')
    }

    const closeForm = () => {
      emit('update-dialog', null)
    }

    return {
      patientFormAddendum,
      closeForm,
      refreshParent,
      required,

      postPatientFormAddendum,
    }
  },
  methods: {
    validate() {
      if (this.patientFormAddendum.value.validate(this.patientFormAddendumDTO)) {
        return true
      }

      return false
    },

    submitForm() {
      console.log('posting', this.patientFormAddendumDTO)

      this.postPatientFormAddendum(this.patientFormAddendumDTO)

      this.closeForm()
      this.refreshParent()
    },
  },
}
</script>
