<template>
  <div>
    <v-card>
      <v-card-text>
        <div>
          <v-row
            v-if="toolbar"
            justify="center"
          >
            <v-col
              md="auto"
            >
              <v-btn
                color="error"
                @click="submitForm(false)"
              >
                Close
              </v-btn>
            </v-col>
            <v-spacer></v-spacer>

            <v-col
              v-if="!isEdit"
              md="auto"
            >
              <v-btn
                color="success"
                @click="exportForm()"
              >
                PDF
              </v-btn>
            </v-col>

            <v-col
              v-if="isEdit"
              md="auto"
            >
              <v-btn
                color="warning"
                @click="postForm(false)"
              >
                Save As Draft
              </v-btn>
            </v-col>
            <v-col
              v-if="isEdit"
              md="auto"
            >
              <v-btn
                color="primary"
                @click="postForm(true)"
              >
                Save
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
    <v-card>
      <v-col
        md="auto"
      >
        <div
          v-if="formData.flowsheet && isEdit && patientId !== undefined"
        >
        <!-- Previous Flowsheets -->
          <previous-forms
            :patient-id="patientId"
            :form="formData.formName"
          ></previous-forms>
        </div>

        <div
          v-if="!isEdit && showAddendum"
        >
        <!-- Add Form Addendum List -->
          <form-addendum-list
            :patient-form-id="patientFormDTO.id"
          ></form-addendum-list>
        </div>
      </v-col>
      <VueHtml2pdf
        :enable-download="false"
        :preview-modal="false"
        :paginate-elements-by-height="1400"
        :filename="fileName"
        :pdf-quality="2"
        :manual-pagination="true"
        pdf-format="a4"
        pdf-orientation="landscape"
        pdf-content-width="1000px"
        ref="html2Pdf"
        :pagebreak="{ mode: ['avoid-all', 'css', 'legacy'] }"
        :html2canvas="{ useCORS: true }"
        @beforeDownload="download($event)"
      >
        <section slot="pdf-content"></section>
      </VueHtml2pdf>

      <section
        ref="form"
        id="form"
      >
        <!-- CoverPage
          Patient Name
          Patient JacketId
          Patient DOB
          Patient Age
          Patient Allergies
         -->

        <!-- Add Patient Allergy Description Code List -->
        <div>
          <patient-chart-allergy
            v-if="isEdit"
            :patient-data="{id: patientId}"
          ></patient-chart-allergy>
          <h3
            v-if="!isEdit && allergyList.length != 0"
          >
            Patient Allergies:
            <v-chip
              v-for="(allergy, index) in allergyList"
              :key="index"
            >
              {{ allergy.allergyDescriptionCode }}
            </v-chip>
          </h3>
        </div>

        <div
          v-if="birthDate != null && birthDate != undefined"
        >
          <h3>
            Patient Birth Date: {{ formatDate(birthDate) }}
          </h3>
          <h3>
            Patient Age: {{ calculateAge(birthDate) }}
          </h3>
        </div>

        <FormRenderer
          v-model="input"
          :form-configuration="formConfig"
          :read-only="read"
        />

        <!-- Add Form Addendum List -->

        <!-- Add footer with Creator Name and Date/Time of export -->
      </section>

      <patient-chart-medications
        v-if="isEdit"
        :patient-data="{id: patientId}"
      ></patient-chart-medications>

      <div
        v-if="!isEdit"
      >
        <h4>Form Input Data</h4>
        <pre class="code">
          <code
            class="'col-md-4'"
            v-html="JSON.stringify(formInput, null, 2)"
          >
          </code>
        </pre>
      </div>
    </v-card>
  </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf'

import formService from '@/services/FormService'
import useFormService from '@/store/useFormService'
import allergyService from '@/services/AllergyService'
import useAllergyList from '@/store/useAllergyService'

import CommonList from '@/store/CommonService'

import store from '@/store'
import { onUnmounted } from '@vue/composition-api'
import FormAddendumList from '@/views/form/FormAddendumList.vue'
import PatientChartMedications from '@/views/patient/chart/PatientChartMedications.vue'
import PatientChartAllergy from '@/views/patient/chart/PatientChartAllergy.vue'
import PreviousForms from './PreviousForms.vue'



export default {
  components: { FormAddendumList, PatientChartMedications, PatientChartAllergy, VueHtml2pdf, PreviousForms },
  name: "form-render",
  props: {
    formData: {
      type: Object,
      default: () => ({}),
    },
    formInputData: {
      type: Object,
      default: () => ({}),
    },
    patientFormDTO: {
      type: Object,
      default: () => ({}),
      required: false,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    toolbar: {
      type: Boolean,
      default: true,
    },
    showAddendum: {
      type: Boolean,
      default: true,
    },
    dob: {
      type: Date,
      default: null,
    }
  },
  data: () => ({
    input: null,
    read: true,
    pdf: true,
  }),
  setup(props, { emit }) {
    let formInput = props.formInputData
    let formConfig = props.formData.patient !== undefined && props.formData.patient != null ? props.formData.formConfiguration : props.formData.formConfiguration
    let patientId = props.formData.patient !== undefined && props.formData.patient != null ? props.formData.patient : props.formInputData.patient
    let fileName = props.formData.formName
    let birthDate = props.patientFormDTO.patient == undefined ? props.dob : props.patientFormDTO.patient.birthDate
    if (props.patientFormDTO.formInput != null && props.patientFormDTO.formInput !== undefined) {
      formInput = props.patientFormDTO.formInput
      formConfig = props.patientFormDTO.formStyle.formConfiguration
      fileName = props.patientFormDTO.formStyle.formName
    }
    const FORM_APP = 'app-form'

    // Register module
    if (!store.hasModule(FORM_APP)) {
      store.registerModule(FORM_APP, formService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FORM_APP)) store.unregisterModule(FORM_APP)
    })
    const { postPatientForm, fetchRecentPatientForms, recentList, postPatientFormDraft } = useFormService()

    const ALLERGY_APP = 'app-allergy'

    // Register module
    if (!store.hasModule(ALLERGY_APP)) {
      store.registerModule(ALLERGY_APP, allergyService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ALLERGY_APP)) {
        store.unregisterModule(ALLERGY_APP)
      }
    })

    const {
      allergyList,
      fetchPatientAllergies,
    } = useAllergyList()

    const { formatDate, calculateAge } = CommonList()

    const submitForm = bool => {
      //console.log('form submit', bool)

      if (!bool) {
        emit('update-dialog', 'render')
        emit('refetch-data')
      } else {
        console.log('form submit', bool)
        emit('update-dialog', 'save')
      }
    }

    return {
      submitForm,
      postPatientForm,
      fetchRecentPatientForms,
      recentList,

      postPatientFormDraft,

      allergyList,
      fetchPatientAllergies,

      formInput,
      formConfig,
      fileName,
      patientId,
      birthDate,

      formatDate,
      calculateAge,
    }
  },
  mounted() {
    console.log('MOUNTED FORM ', this.patientFormDTO, this.formInputData, this.formData)
    this.setData()
    if (this.formInputData.patient != null || this.formInputData.patient !== undefined) {
      this.fetchPatientAllergies(this.formInputData.patient)
      this.$watch('allergyList', () => {
        console.log('allergyList', this.allergyList)
      })
    }
  },
  methods: {
    download(event) {
      const { html2pdf, options } = event
      console.log('Download')
      html2pdf().set(options).from(document.getElementById('form')).toPdf()
      .get('pdf')
      .then(pdf => {
        const totalPages = pdf.internal.getNumberOfPages()
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i)
          pdf.setFontSize(10)
          pdf.setTextColor(150)
          pdf.text('Page ' + i + ' of ' + totalPages, (pdf.internal.pageSize.getWidth() * 0.88), (pdf.internal.pageSize.getHeight() - 0.3))
        }
      })
      .save()
    },
    setData() {
      console.log('CREATED', this.formInputData, this.input)
      this.$set(this, 'input', this.formInputData)
      if (this.input != null) {
        this.read = false
      }
      console.log('set', this.input, this.formInputData, this.read)
    },
    postForm(bool) {
      let status = "Complete"
      if (!bool){
        status = "Draft"
      }

      this.formInput.MARDistributionId = this.formInputData.marDistributionId

      const formId = this.patientFormDTO.id !== undefined && this.patientFormDTO.id != null ? this.patientFormDTO.id : this.formData.id
      const configId = this.patientFormDTO.id !== undefined && this.patientFormDTO.id != null ? this.patientFormDTO.formStyle.id : this.formData.id

      console.log('FormID', formId)
      const patientFormDTO = {
        id: formId,
        formInput: this.input,
        patientId:
          this.input.patient == null || this.input.patient === undefined
            ? this.patientId
            : this.input.patient,
        status: status,
        formStyleId: configId,
        isActive: true,
      }
      console.log('Data', patientFormDTO)

      if (!bool){
        this.postPatientFormDraft(patientFormDTO)
      }
      else{
        this.postPatientForm(patientFormDTO)
        //console.log('Submit')
        this.submitForm(bool)
      }


      this.submitForm(bool)
    },
    exportForm() {
      this.$refs.html2Pdf.generatePdf()
      this.exportUploads()
    },
    exportUploads(){
      if(this.formInputData.fileUpload !== undefined && this.formInputData.fileUpload.length > 0){
        this.formInputData.fileUpload.forEach((element, index) => {
          const downloadLink = document.createElement("a")
          downloadLink.href = element
          downloadLink.download = index + 1
          downloadLink.click()
        })
      }
    }
  },
}
</script>

<style scoped>
pre {
  background: #333;
  white-space: pre;
  word-wrap: break-word;
  overflow: auto;
}
pre.code {
  margin: 20px 0;
  border-radius: 4px;
  border: 1px solid #292929;
  position: relative;
}
pre.code code {
  display: block;
  padding: 15px 16px 14px;
  border-left: 1px solid #555;
  overflow-x: auto;
  font-size: 13px;
  line-height: 19px;
  color: #ddd;
}
</style>
