import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"previous-form-list"}},[_c(VDialog,{attrs:{"persistent":_vm.isEdit,"max-width":"75%"},model:{value:(_vm.renderDialog),callback:function ($$v) {_vm.renderDialog=$$v},expression:"renderDialog"}},[_c('div',[_c(VCard,[(_vm.renderDialog)?_c('form-render',{attrs:{"form-data":_vm.selectedForm.formStyle,"form-input-data":_vm.selectedItem,"patient-form-d-t-o":_vm.selectedForm,"is-edit":false},on:{"update-dialog":_vm.updateDialog},model:{value:(_vm.renderDialog),callback:function ($$v) {_vm.renderDialog=$$v},expression:"renderDialog"}}):_vm._e()],1)],1)]),_c(VCard,[_c(VCardTitle,[_vm._v(" Previous Forms")]),_c(VDataTable,{attrs:{"headers":_vm.patientFormColumns,"items":_vm.recentList,"loading":_vm.loading,"items-per-page":5},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){_vm.renderForm(item), _vm.updateDialog('render')}}},[_vm._v(" Details ")])]}}],null,true),model:{value:(_vm.selectedRow),callback:function ($$v) {_vm.selectedRow=$$v},expression:"selectedRow"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }