<template>
  <div id="previous-form-list">
    <!-- dialog -->

    <v-dialog
      v-model="renderDialog"
      :persistent="isEdit"
      max-width="75%"
    >
      <div>
        <v-card>
          <form-render
            v-if="renderDialog"
            v-model="renderDialog"
            :form-data="selectedForm.formStyle"
            :form-input-data="selectedItem"
            :patient-form-d-t-o="selectedForm"
            :is-edit="false"
            @update-dialog="updateDialog"
          ></form-render>
        </v-card>
      </div>
    </v-dialog>

    <v-card>
      <v-card-title> Previous Forms</v-card-title>
      <!-- table -->
      <v-data-table
        v-model="selectedRow"
        :headers="patientFormColumns"
        :items="recentList"
        :loading="loading"
        :items-per-page="5"
      >
        <!-- actions -->
        <template #[`item.actions`]="{item}">
          <v-btn
            color="primary"
            @click="renderForm(item), updateDialog('render')"
          >
            Details
          </v-btn>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import {
  mdiAccountOutline,
  mdiAlertCircleOutline,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiExportVariant,
  mdiFileDocumentOutline,
  mdiOfficeBuilding,
  mdiPlus,
  mdiSquareEditOutline,
  mdiAccountEditOutline,
  mdiAccountLockOutline,
} from '@mdi/js'

import formService from '@/services/FormService'
import useFormService from '@/store/useFormService'

export default {
  components: {
    FormRender: () => import('./FormRender.vue')
  },
  name: "previous-forms",
  props: {
    patientId: {
      type: String,
      default: null,
      required: true,
    },
    form: {
      type: String,
      default: null,
      required: true,
    },
  },
  setup() {
    const FORM_APP = 'app-form'

    // Register module
    if (!store.hasModule(FORM_APP)) {
      store.registerModule(FORM_APP, formService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      console.log('unMounted Form Render')
      if (store.hasModule(FORM_APP)) {
        store.unregisterModule(FORM_APP)
      }
    })

    const {
      fetchRecentPatientForms,
      recentList,
      patientFormColumns,
      selectedForm,
      selectedRow,
      loading,
    } = useFormService()

    const isEdit = ref(false)
    const selectedItem = ref({})
    const isDeleteDialogActive = ref(false)
    const renderDialog = ref(false)
    const input = null

    return {
      fetchRecentPatientForms,
      recentList,
      renderDialog,
      selectedItem,
      isEdit,
      isDeleteDialogActive,
      patientFormColumns,
      selectedForm,
      selectedRow,
      loading,
      input,

      icons: {
        mdiAccountOutline,
        mdiAlertCircleOutline,
        mdiDeleteOutline,
        mdiDotsVertical,
        mdiExportVariant,
        mdiFileDocumentOutline,
        mdiOfficeBuilding,
        mdiPlus,
        mdiSquareEditOutline,
        mdiAccountEditOutline,
        mdiAccountLockOutline,
      },
    }
  },
  mounted() {
    this.fetchRecentPatientForms(this.patientId, this.form)
    this.$watch('recentList', () => {
      console.log('recentList', this.recentList)
    })
  },
  methods: {
    renderForm(formData) {
      this.selectedForm = formData
      this.selectedItem = formData.formInput
      console.log('Form Data Set', formData, this.selectedForm, this.selectedItem)

    },
    updateDialog(dialog) {
      if (dialog === 'render') {
        if (this.renderDialog) {
          console.log('Render Empty')
          this.selectedForm = {}
          this.selectedItem = {}
        }
        if (this.selectedForm == null) {
          this.renderDialog = false
        }

        this.$nextTick(() =>{
          console.log('Render Form', this.selectedForm, this.selectedItem, this.renderDialog)
          this.renderDialog = !this.renderDialog
        })

      }
    },
  },
}
</script>
