<template>
  <div>
    <!-- dialog -->
    <v-card>
      <form-addendum-add-edit
        v-if="isAddendumFormActive"
        :is-addendum-form-active="isAddendumFormActive"
        :patient-form-addendum-d-t-o="selectedForm"
        @update-dialog="updateDialog"
      ></form-addendum-add-edit>
    </v-card>

    <v-card id="form-addendum-list">
      <v-card-text>
        <div>
          <!-- create form -->
          <v-btn
            v-model="isAddendumFormActive"
            color="primary"
            class="me-3"
            @click="selectRow(), updateDialog('edit')"
          >
            <v-icon
              size="18"
              class="me-1"
            >
              {{ icons.mdiPlus }}
            </v-icon>
            <span>Create Form Addendum</span>
          </v-btn>
        </div>
      </v-card-text>

      <!-- table -->
      <v-data-table
        v-model="selectedRow"
        :headers="formAddendumColumns"
        :items="formAddendumList"
        :options.sync="options"
        :loading="loading"
        class="text-no-wrap"
        sort-by="recordedDate"
        :sort-desc="true"
        render
      >
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import {
  mdiPlus,
} from '@mdi/js'

import formService from '@/services/FormService'
import useFormService from '@/store/useFormService'

import store from '@/store'
import { onUnmounted, ref } from '@vue/composition-api'
import FormAddendumAddEdit from '@/views/form/FormAddendumAddEdit.vue'

export default {
  components: { FormAddendumAddEdit },
  props: {
    patientFormId: {
      type: String,
      default: null,
      required: true,
    },
  },
  data: () => ({
    formInputData: null,
    isShowData: false,
    readOnly: false,
    renderDialog: false,
  }),
  setup() {
    console.log('Addendum List Reached')
    const FORM_APP = 'app-form'

    // Register module
    if (!store.hasModule(FORM_APP)) {
      store.registerModule(FORM_APP, formService)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(FORM_APP)) store.unregisterModule(FORM_APP)
    })
    const {
      fetchPatientFormAddendums,
      formAddendumList,
      loading,
      searchQuery,
      formAddendumColumns,
      selectedForm,
      resolveFormStatusVariantAndIcon,
      selectedRow,
      options,
      statusFilter,
      formData,
      groupFilter,
    } = useFormService()

    const isAddendumFormActive = ref(false)

    return {
      statusFilter,
      options,

      selectedRow,
      selectedForm,
      resolveFormStatusVariantAndIcon,
      fetchPatientFormAddendums,
      formAddendumList,
      loading,
      formAddendumColumns,
      searchQuery,
      formData,
      groupFilter,

      isAddendumFormActive,

      icons: {
        mdiPlus,
      },
    }
  },
  mounted() {
    console.log('Form Addendums ', this.patientFormId)
    this.fetchPatientFormAddendums(this.patientFormId)
  },
  methods: {
    log(msg) {
      console.log(msg)
    },
    selectRow() {
      this.selectedForm = { patientFormId: this.patientFormId }
    },
    updateDialog() {
      console.log('Dialog', this.isAddendumFormActive)
      if (this.isAddendumFormActive) {
        this.selectedForm = {}
      }
      this.isAddendumFormActive = !this.isAddendumFormActive
      console.log('Selected Form', this.selectedForm)
    },
  },
}
</script>
